.music-player-container {
  position: fixed;
  bottom: 0;
  background-color: black;
  width: 100%;
  height: 12vh;
  z-index: 3;
  display: flex;
  align-items: center;
}

.audio-player {
  display: none;
}

.content {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.audio-title {
  width: 20%;
  font-size: 0.9rem;
  text-transform: uppercase;
  p {
    line-height: 20px;
    text-align: left;
  }
}

.progress {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  .progress-bar {
    width: 40vw;
    background-color: transparent;
    cursor: pointer;
    --seek-before-width: 0;
  }
  .time-duration {
    p {
      width: 40px;
      text-align: left;
    }
  }
}

.buttons {
  .play-pause-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    border-radius: 50px;
    height: 35px;
    width: 35px;
    color: #f48d32;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: #f48d32;
    }
  }
}

.volume {
  display: flex;
  align-items: center;
  gap: 20px;
  .volume-control-bar {
    background: transparent;
  }
}

// Tablet & Mobile View
@media only screen and (max-width: 1200px) {
  .music-player-container {
    z-index: 5;
    height: 10vh;
    width: 100%;
  }
  .content {
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .buttons {
    order: 1;
    .play-pause-button {
      border: none !important;
    }
  }
  .audio-title {
    order: 2;
    width: 80%;
  }
  .audio-title p {
    font-size: 1rem;
    text-align: left;
  }
  .volume {
    display: none;
  }
  .progress {
    display: none;
  }
}

input[type="range"] {
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #f48d32;
  height: 10px;
  width: 10px;
  cursor: pointer;
  border-radius: 50px;
}

/* Track Styling */

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  cursor: pointer;
  background: black;
  border: 1px solid #f48d32;
  border-radius: 8px;
  padding: 2px 2px;
}
