.mixes {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  margin: 0 auto;
  text-align: left;
  padding-top: 15vh;
  padding-bottom: 12vh;
  position: relative;
  .radio {
    width: 140px;
  }
  .home {
    width: 250px;
  }
  img {
    height: 50px;
    padding-top: 20px;
    padding-bottom: 50px;
  }

  a {
    text-decoration: none;
  }
}

.single-mix {
  .subtitle {
    font-size: 1.1rem !important;
  }
}

.mix-row {
  margin: 0 auto;
  width: 95%;
}

.mix-items {
  margin: 0 auto;
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  video,
  image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.mix-container {
  position: relative;
  width: 18vw;
  .card-video,
  img {
    width: 100%;
    height: 18vw;
  }
  .card-text {
    height: 100px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    .top {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .title {
      text-transform: uppercase;
      color: white;
      font-size: 1rem;
      letter-spacing: 1px;
    }
    p {
      padding: 0;
      margin: 0;
      line-height: 20px;
      text-align: left;
      letter-spacing: 0px;
    }
    p:not(.title) {
      color: grey;
      font-size: 0.8rem;
    }
  }
}

.play-pause {
  color: #f48d32;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  font-size: 4rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: white;
  }
}

@media only screen and (max-width: 820px) {
  .mixes {
    padding-bottom: 15vh !important;
    .radio {
      width: 100px;
    }
    .home {
      width: 150px;
      padding-top: 20px;
    }
    img {
      height: 35px;
      padding-top: 0px;
      padding-bottom: 30px;
    }
  }
  .mix {
    height: auto;
  }
  .mix-items {
    flex-direction: column;
    gap: 20px;
  }

  .mix-container {
    width: 80vw;
  }
  .card-video {
    height: 80vw !important;
    img,
    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
  .card-text {
    height: 80px !important;
  }
}
