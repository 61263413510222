.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  height: 100%;

  .grid-item {
    width: 24%;
    max-height: 100%;
    position: relative;
    object-fit: cover;

    cursor: pointer;
    &:hover {
      .background {
        filter: brightness(1);
      }
    }
  }

  .overlay {
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .birdguy {
    width: 270px !important;
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: auto;
    width: 140px;
    p {
      padding: 0;
      margin: 0;
      font-size: 1.2rem;
      text-align: center;
      line-height: 35px;
    }
    h3 {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      font-size: 1.5rem;
      font-weight: 300;
      text-transform: capitalize;
    }
    img {
      object-fit: contain;
      filter: opacity(0.9);
    }
  }

  .background {
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: brightness(0.6);
    transition: all 0.2s ease-in-out;
    &:hover {
      filter: brightness(1);
    }
  }

  img,
  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.events {
  .grid-item {
    width: 32% !important;
    img {
      object-fit: contain !important;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .grid {
    .background {
      filter: brightness(1) blur(0);
    }
  }
  .title {
    img {
      height: auto;
      width: 100%;
    }
  }
  .grid-item {
    width: 100% !important;
    height: 60vh !important;
    margin-bottom: 20px !important;
  }

  .events {
    .grid-item {
      width: 100% !important;
      height: 60vh !important;
      margin-bottom: 20px !important;
      img {
        object-fit: contain !important;
      }
    }
  }
}
