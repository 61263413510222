.navbar {
  background-color: black;
  width: 100%;
  height: 15vh;
  position: fixed;
  z-index: 1000;
  top: 0;
  &-contents {
    height: 100%;
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .navbar-left {
    .navbar-logo {
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: absolute;
      height: 60px;
      width: 60px;
      img {
        height: 100%;
        width: 100%;
      }
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .navbar-middle {
    z-index: 1000;
    img {
      height: 50px;
    }
  }

  .navbar-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: all 0.2s ease-in-out;
    .navbar-links {
      display: none;
    }
    .active-link {
      color: white;
    }
    .navbar-links-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: black;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 999;
      gap: 40px;
      a {
        font-size: 1.2rem;
        text-decoration: none;
        line-height: 30px;
        font-weight: 200;
      }
    }
    .navbar-icons {
      position: absolute;
      z-index: 1000;
      padding-right: 20px;
      cursor: pointer;
      font-size: 24px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .navbar-left {
    img {
      height: 40px !important;
      width: 40px !important;
    }
  }
  .navbar-middle {
    img {
      height: 30px !important;
    }
  }
  .navbar-icons {
    padding-right: 10px !important;
  }
}
