@import "common/fonts";

// Components
@import "components/_navbar.scss";
@import "components/_musicplayer.scss";
@import "components/_grid.scss";
@import "components/_singleItem.scss";
@import "components/_loading-error.scss";

// Pages
@import "pages/_about.scss";
@import "pages/_home.scss";
@import "pages/_contact.scss";
@import "pages/_mix.scss";
@import "pages/_mixes.scss";
@import "pages/library";
@import "pages/events";

// Extra
@import "~react-image-gallery/styles/scss/image-gallery.scss";

html {
  max-height: 100vh;
  background-color: black;
}

html::-webkit-scrollbar {
  display: none;
}

body,
a {
  color: #f48d32;
  font-family: "Josefin Sans", sans-serif;
}

h1 {
  font-weight: 400 !important;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 2rem;
}

h3 {
  letter-spacing: 2px;
  font-size: 1.5rem;
  font-weight: 300;
  text-transform: uppercase !important;
}

p {
  line-height: 40px;
  text-align: justify;
  font-weight: 200;
}

a {
  letter-spacing: 2px;
  font-weight: 100;
  font-size: 0.9rem;
}

.content-container {
  padding-top: 15vh;
  height: 73vh;
  position: relative;
}

.page-container {
  display: flex;
  justify-content: center;
}

.route-container {
  width: 100%;
}

.page-container.fade-in {
  opacity: 1;
}

.content-container::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 1200px) {
  html {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .content-container {
    height: auto;
    max-width: 100vw;
    padding-bottom: 12vh;
  }
}

@media only screen and (max-width: 820px) {
  html {
    overflow-y: scroll;
  }
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  p {
    font-size: 0.9rem;
  }
}
