.library {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20vh;
  .title-img {
    width: 150px;
  }

  &-text {
    text-align: center;
    font-size: 1.2rem;
    font-style: italic;
    line-height: 30px;
    padding-top: 200px;
    margin-bottom: 80px;
    width: 50%;
  }

  &-menu {
    display: flex;
    position: fixed;
    left: -190px;
    width: 50vh;
    justify-content: space-between;
    transform: rotate(270deg);
    a {
      text-decoration: none;
      font-size: 1.2rem;
      transition: all 0.2s ease-in-out;
      &:hover {
        color: white;
      }
    }
    .active-page {
      color: white;
    }
  }
  .grid-item {
    width: 32%;
    height: 100%;
  }
  .grid {
    .title {
      width: 100%;
      p {
        letter-spacing: 0px !important;
      }
    }
  }
}

.library {
  .grid-item {
    width: 32%;
    height: 73vh !important;
  }
}

@media only screen and (max-width: 1200px) {
  .library-text {
    padding-top: 0 !important;
    margin-bottom: 80px !important;
  }
  .library-menu {
    display: none;
  }
}
