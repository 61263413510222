.contact {
  &-block {
    display: flex;
    align-items: center;

    gap: 10px;
    p {
      margin: 0;
      line-height: 50px;
    }
  }
  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .media-container {
    cursor: initial;
    img {
      filter: blur(0) brightness(1);
    }
  }
}

@media only screen and (max-width: 1200px) {
  .contact {
    .left {
      order: 1;
    }
    .title-container {
      margin-bottom: 0;
    }
    .text-container {
      height: 250px;
    }
  }
}
