.about-container {
  .text-container {
    display: flex;
  }
}

@media only screen and (max-width: 1200px) {
  .about {
    &-container {
      text-align: left;
      flex-direction: column;
      align-items: center;
      gap: 0;
      height: auto;
      margin: 0 auto;
      .text-container {
        margin: 0 auto;
        margin-bottom: 40px;
      }
      .media-container img {
        height: 60vh;
      }
    }
  }
}
