.home {
  width: 90%;
  margin: 0 auto;
}

.home-video {
  position: relative;
  height: 73vh;
  width: 100%;
  filter: blur(2px);
  &:hover {
    filter: blur(0);
  }
  video,
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

@media only screen and (max-width: 1200px) {
  .home-video {
    filter: blur(0);
  }
}
